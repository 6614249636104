export function FormPopupSchema(){
    this.schema = {
        show: false,
        url: null,
        path: null,
        username: null,
        okLabel: null,
        closeLabel: null,
        okCallback: () => {},
        closeCallback: () => {}
    }
    return this.schema;
} 