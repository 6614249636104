import {FormPopupSchema} from "../../schemas/prompts/forms/investor/form"

const initialState = new FormPopupSchema()

const investorAuthFormPopupReducer = (state = initialState, action) => {
    switch (action.type) {
        case `SHOW_INVESTOR_AUTH_FORM_POPUP`:
            return action.payload
        case `HIDE_INVESTOR_AUTH_FORM_POPUP`:
            return initialState
        default:
            return state
    }
}

export default investorAuthFormPopupReducer