import {DisclaimerPopupSchema} from "../../../schemas/prompts/disclaimers/nonUkResidents/hcp"

const initialState = new DisclaimerPopupSchema()

const nonUkResidentsHcpDisclaimerReducer = (state = initialState, action) => {
    switch (action.type) {
        case `SHOW_NON_UK_RESIDENTS_HCP_DISCLAIMER_POPUP`:
            return action.payload
        case `HIDE_NON_UK_RESIDENTS_HCP_DISCLAIMER_POPUP`:
            return initialState
        default:
            return state
    }
}

export default nonUkResidentsHcpDisclaimerReducer