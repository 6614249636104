const initialState = ""

const ukNonHcpSearchTermReducer = (state = initialState, action) => {
    switch (action.type) {
        case `UK_NON_HCP_SEARCH_ONCHANGE`:
            return action.payload;
        default:
            return state
    }
}

export default ukNonHcpSearchTermReducer