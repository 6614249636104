import {DisclaimerPopupSchema} from "../../schemas/prompts/disclaimers/investor"

const initialState = new DisclaimerPopupSchema()

const investorDisclaimerReducer = (state = initialState, action) => {
    switch (action.type) {
        case `SHOW_INVESTOR_DISCLAIMER_POPUP`:
            return action.payload
        case `HIDE_INVESTOR_DISCLAIMER_POPUP`:
            return initialState
        default:
            return state
    }
}

export default investorDisclaimerReducer