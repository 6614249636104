export function AwardsPopupSchema(){
    this.schema = {
        show: false,
        award: null,
        okLabel: null,
        closeLabel: null,
        okCallback: () => {},
        closeCallback: () => {}
    }
    return this.schema;
} 