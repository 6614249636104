import {ApplyOnlinePopupSchema} from "../../../schemas/prompts/careers/nonUkResidents/hcp/apply-online"

const initialState = new ApplyOnlinePopupSchema()

const nonUkHcpApplyOnlinePopupReducer = (state = initialState, action) => {
    switch (action.type) {
        case `SHOW_NON_UK_HCP_APPLY_ONLINE_POPUP`:
            return action.payload.payload
        case `HIDE_NON_UK_HCP_APPLY_ONLINE_POPUP`:
            return initialState
        default:
            return state
    }
}

export default nonUkHcpApplyOnlinePopupReducer