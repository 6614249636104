export function ApplyOnlinePopupSchema(){
    this.schema = {
        show: false,
        title: null,
        jobPosition: null,
        jobType: null,
        country: null,
        location: null,
        okLabel: null,
        closeLabel: null,
        okCallback: () => {},
        closeCallback: () => {}
    }
    return this.schema;
} 