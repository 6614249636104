import {DisclaimerPopupSchema} from "../../../schemas/prompts/disclaimers/ukResidents/hcp"

const initialState = new DisclaimerPopupSchema()

const ukResidentsHcpDisclaimerReducer = (state = initialState, action) => {
    switch (action.type) {
        case `SHOW_UK_RESIDENTS_HCP_DISCLAIMER_POPUP`:
            return action.payload
        case `HIDE_UK_RESIDENTS_HCP_DISCLAIMER_POPUP`:
            return initialState
        default:
            return state
    }
}

export default ukResidentsHcpDisclaimerReducer