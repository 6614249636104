import React from 'react'
import { createStore } from 'redux'
import { Provider } from 'react-redux'
import rootReducer from "./src/reducers"
import Amplify from "aws-amplify";

const awsconfigDev = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:5a31ee26-890c-4765-b37d-4ba7335d5c5f",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_qpa9IMOtt",
    "aws_user_pools_web_client_id": "24a67cokvdd90a5nsie641etau",
    "oauth": {},
    "aws_user_files_s3_bucket": "diurnal-assets180018-dev",
    "aws_user_files_s3_bucket_region": "eu-west-1",
    "aws_cloud_logic_custom": [
        {
            "name": "DiurnalApi",
            "endpoint": "https://tijgq1yaek.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        }
    ]
}

const awsconfigProd = {
    "aws_project_region": "eu-west-1",
    "aws_cognito_identity_pool_id": "eu-west-1:2814cad1-5a56-42ff-b16e-1ef4a75193b1",
    "aws_cognito_region": "eu-west-1",
    "aws_user_pools_id": "eu-west-1_hTpqQeghr",
    "aws_user_pools_web_client_id": "5gv5ph0onalg2hm69ilten7t7v",
    "oauth": {},
    "aws_user_files_s3_bucket": "diurnal-assets172853-prod",
    "aws_user_files_s3_bucket_region": "eu-west-1",
    "aws_cloud_logic_custom": [
        {
            "name": "DiurnalApi",
            "endpoint": "https://tijgq1yaek.execute-api.eu-west-1.amazonaws.com/dev",
            "region": "eu-west-1"
        }
    ]
};

const configToUse = process.env.GATSBY_ACTIVE_ENV === "production" ? awsconfigProd : awsconfigDev;

Amplify.configure(configToUse);

const initialState = {};

export default ({ element }) => {
    const store = createStore(rootReducer, initialState);
    return <Provider store={store}>{element}</Provider>
} 