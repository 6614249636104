const initialState = ""

const nonUkNonHcpSearchTermReducer = (state = initialState, action) => {
    switch (action.type) {
        case `NON_UK_NON_HCP_SEARCH_ONCHANGE`:
            return action.payload;
        default:
            return state
    }
}

export default nonUkNonHcpSearchTermReducer