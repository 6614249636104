export function FranceCaptchaPopupSchema(){
    this.schema = {
        title: null,
        message: null,
        show: false,
        okLabel: null,
        closeLabel: null,
        okCallback: () => {},
        closeCallback: () => {}
    }
    return this.schema;
}