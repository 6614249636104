import {ApplyOnlinePopupSchema} from "../../schemas/prompts/careers/investor/apply-online"

const initialState = new ApplyOnlinePopupSchema()

const investorApplyOnlinePopupReducer = (state = initialState, action) => {
    switch (action.type) {
        case `SHOW_INVESTOR_APPLY_ONLINE_POPUP`:
            return action.payload.payload
        case `HIDE_INVESTOR_APPLY_ONLINE_POPUP`:
            return initialState
        default:
            return state
    }
}

export default investorApplyOnlinePopupReducer