import {FormPopupSchema} from "../../../schemas/prompts/forms/nonUkResidents/hcp/form"

const initialState = new FormPopupSchema()

const nonUkHcpAuthFormPopupReducer = (state = initialState, action) => {
    switch (action.type) {
        case `SHOW_NON_UK_HCP_AUTH_FORM_POPUP`:
            return action.payload
        case `HIDE_NON_UK_HCP_AUTH_FORM_POPUP`:
            return initialState
        default:
            return state
    }
}

export default nonUkHcpAuthFormPopupReducer