const initialState = ""

const investorSearchTermReducer = (state = initialState, action) => {
    switch (action.type) {
        case `INVESTOR_SEARCH_ONCHANGE`:
            return action.payload;
        default:
            return state
    }
}

export default investorSearchTermReducer