import {DisclaimerPopupSchema} from "../../schemas/prompts/disclaimers/main-landing"

const initialState = new DisclaimerPopupSchema()

const landingDisclaimerReducer = (state = initialState, action) => {
    switch (action.type) {
        case `SHOW_MAIN_LANDING_DISCLAIMER_POPUP`:
            return action.payload
        case `HIDE_MAIN_LANDING_DISCLAIMER_POPUP`:
            return initialState
        default:
            return state
    }
}

export default landingDisclaimerReducer