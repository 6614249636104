import {EmailFriendPopupSchema} from "../../schemas/prompts/careers/investor/email-friend"

const initialState = new EmailFriendPopupSchema()

const investorEmailFriendPopupReducer = (state = initialState, action) => {
    switch (action.type) {
        case `SHOW_INVESTOR_EMAIL_FRIEND_POPUP`:
            return action.payload.payload
        case `HIDE_INVESTOR_EMAIL_FRIEND_POPUP`:
            return initialState
        default:
            return state
    }
}

export default investorEmailFriendPopupReducer