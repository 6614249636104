import {EmailFriendPopupSchema} from "../../../schemas/prompts/careers/nonUkResidents/nonHcp/email-friend"

const initialState = new EmailFriendPopupSchema()

const nonUkNonHcpEmailFriendPopupReducer = (state = initialState, action) => {
    switch (action.type) {
        case `SHOW_NON_UK_NON_HCP_EMAIL_FRIEND_POPUP`:
            return action.payload.payload
        case `HIDE_NON_UK_NON_HCP_EMAIL_FRIEND_POPUP`:
            return initialState
        default:
            return state
    }
}

export default nonUkNonHcpEmailFriendPopupReducer