import {DisclaimerPopupSchema} from "../../schemas/prompts/disclaimers/ukResidents/user-selection-disclaimer"

const initialState = new DisclaimerPopupSchema()

const ukResidentsUserSelectionDisclaimerReducer = (state = initialState, action) => {
    switch (action.type) {
        case `SHOW_UK_RESIDENTS_USER_SELECTION_DISCLAIMER`:
            return action.payload
        case `HIDE_UK_RESIDENTS_USER_SELECTION_DISCLAIMER`:
            return initialState
        default:
            return state
    }
}

export default ukResidentsUserSelectionDisclaimerReducer