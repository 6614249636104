import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import "animate.css/animate.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./src/base.css"
import provideStores from './provide-stores'

export const wrapRootElement = provideStores

export function onClientEntry() {
    let pathNames = [
        "UkResidents-Landing",
        "NonUkResidents-Landing",
        "ukresidents-hcp",
        "ukresidents-nonhcp",
        "ukresidents-nonhcp-info-for-uk-patients",
        //Pending
        "investor-home",
        "nonukresidents-hcp",
        "nonukresidents-hcp-german-prescribers",
        "nonukresidents-nonhcp-info-for-german-patients"
    ]
    if (document.referrer.toLowerCase().includes("diurnal.com") || document.referrer.toLowerCase().includes("diurnal.co.uk")){
        console.log("Refered by diurnal so dont clear localstrage...");
        return;
    }
    console.log("Not refered by diurnal so clear localstrage...");

    for (var i = 0; i < pathNames.length; i++){
        localStorage.removeItem(pathNames[i]);
    }
}