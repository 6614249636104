import {EmailFriendPopupSchema} from "../../../schemas/prompts/careers/nonUkResidents/hcp/email-friend"

const initialState = new EmailFriendPopupSchema()

const nonUkHcpEmailFriendPopupReducer = (state = initialState, action) => {
    switch (action.type) {
        case `SHOW_NON_UK_HCP_EMAIL_FRIEND_POPUP`:
            return action.payload.payload
        case `HIDE_NON_UK_HCP_EMAIL_FRIEND_POPUP`:
            return initialState
        default:
            return state
    }
}

export default nonUkHcpEmailFriendPopupReducer