const initialState = ""

const ukHcpSearchTermReducer = (state = initialState, action) => {
    switch (action.type) {
        case `UK_HCP_SEARCH_ONCHANGE`:
            return action.payload;
        default:
            return state
    }
}

export default ukHcpSearchTermReducer