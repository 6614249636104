export function EmailFriendPopupSchema(){
    this.schema = {
        show: false,
        title: null,
        description: null,
        okLabel: null,
        closeLabel: null,
        okCallback: () => {},
        closeCallback: () => {}
    }
    return this.schema;
} 