import { combineReducers } from "redux";
import landingDisclaimerReducer from "./main-landing/disclaimer"
import investorDisclaimerReducer from "./investor/disclaimer"
import ukResidentsHcpDisclaimerReducer from "./ukResidents/hcp/disclaimer"
import ukResidentsNonHcpDisclaimerReducer from "./ukResidents/nonHcp/disclaimer"
import nonUkResidentsHcpDisclaimerReducer from "./nonUkResidents/hcp/disclaimer"
import nonUkResidentsNonHcpDisclaimerReducer from "./nonUkResidents/nonHcp/disclaimer"
import ukResidentsUserSelectionDisclaimer from "./ukResidents/user-selection-disclaimer"
import nonUkResidentsUserSelectionDisclaimer from "./nonUkResidents/user-selection-disclaimer"
import investorApplyOnlinePopupReducer from "./investor/apply-online"
import investorEmailFriendPopupReducer from "./investor/email-friend"
import ukHcpApplyOnlinePopupReducer from "./ukResidents/hcp/apply-online"
import ukHcpEmailFriendPopupReducer from "./ukResidents/hcp/email-friend"
import ukNonHcpApplyOnlinePopupReducer from "./ukResidents/nonHcp/apply-online"
import ukNonHcpEmailFriendPopupReducer from "./ukResidents/nonHcp/email-friend"
import nonUkHcpApplyOnlinePopupReducer from "./nonUkResidents/hcp/apply-online"
import nonUkHcpEmailFriendPopupReducer from "./nonUkResidents/hcp/email-friend"
import nonUkNonHcpApplyOnlinePopupReducer from "./nonUkResidents/nonHcp/apply-online"
import nonUkNonHcpEmailFriendPopupReducer from "./nonUkResidents/nonHcp/email-friend"
import ukHcpAwardsApplicationPopupReducer from "./ukResidents/hcp/awards"
import nonUkHcpAwardsApplicationPopupReducer from "./nonUkResidents/hcp/awards"
import investorSearchTermReducer from "./investor/search"
import ukHcpSearchTermReducer from "./ukResidents/hcp/search"
import ukNonHcpSearchTermReducer from "./ukResidents/nonHcp/search"
import nonUkHcpSearchTermReducer from "./nonUkResidents/hcp/search"
import nonUkNonHcpSearchTermReducer from "./nonUkResidents/nonHcp/search"
import investorAuthFormPopupReducer from "./investor/form-popup"
import franceCaptchaPopupReducer from "./shared/france-captcha-popup"
import nonUkHcpAuthFormPopupReducer from "./nonUkResidents/hcp/form-popup"

const rootReducer = combineReducers({
  mainLandingDisclaimerPopup: landingDisclaimerReducer,
  investorDisclaimerPopup: investorDisclaimerReducer,
  ukResidentsHcpDisclaimerPopup: ukResidentsHcpDisclaimerReducer,
  ukResidentsNonHcpDisclaimerPopup: ukResidentsNonHcpDisclaimerReducer,
  nonUkResidentsHcpDisclaimerPopup: nonUkResidentsHcpDisclaimerReducer,
  nonUkResidentsNonHcpDisclaimerPopup: nonUkResidentsNonHcpDisclaimerReducer,
  ukResidentsUserSelectionDisclaimerPopup: ukResidentsUserSelectionDisclaimer,
  nonUkResidentsUserSelectionDisclaimerPopup: nonUkResidentsUserSelectionDisclaimer,
  investorApplyOnlinePopup: investorApplyOnlinePopupReducer,
  investorEmailFriendPopup: investorEmailFriendPopupReducer,
  ukHcpApplyOnlinePopup: ukHcpApplyOnlinePopupReducer,
  ukHcpEmailFriendPopup: ukHcpEmailFriendPopupReducer,
  ukNonHcpApplyOnlinePopup: ukNonHcpApplyOnlinePopupReducer,
  ukNonHcpEmailFriendPopup: ukNonHcpEmailFriendPopupReducer,
  nonUkHcpApplyOnlinePopup: nonUkHcpApplyOnlinePopupReducer,
  nonUkHcpEmailFriendPopup: nonUkHcpEmailFriendPopupReducer,
  nonUkNonHcpApplyOnlinePopup: nonUkNonHcpApplyOnlinePopupReducer,
  nonUkNonHcpEmailFriendPopup: nonUkNonHcpEmailFriendPopupReducer,
  ukHcpAwardsApplicationPopup: ukHcpAwardsApplicationPopupReducer,
  nonUkHcpAwardsApplicationPopup: nonUkHcpAwardsApplicationPopupReducer,
  investorSearchTerm: investorSearchTermReducer,
  ukHcpSearchTerm: ukHcpSearchTermReducer,
  ukNonHcpSearchTerm: ukNonHcpSearchTermReducer,
  nonUkHcpSearchTerm: nonUkHcpSearchTermReducer,
  nonUkNonHcpSearchTerm: nonUkNonHcpSearchTermReducer,
  investorAuthFormPopup: investorAuthFormPopupReducer,
  franceCaptchaPopup: franceCaptchaPopupReducer,
  nonUkHcpAuthFormPopup: nonUkHcpAuthFormPopupReducer
})

export default rootReducer