import {ApplyOnlinePopupSchema} from "../../../schemas/prompts/careers/ukResidents/hcp/apply-online"

const initialState = new ApplyOnlinePopupSchema()

const ukHcpApplyOnlinePopupReducer = (state = initialState, action) => {
    switch (action.type) {
        case `SHOW_UK_HCP_APPLY_ONLINE_POPUP`:
            return action.payload.payload
        case `HIDE_UK_HCP_APPLY_ONLINE_POPUP`:
            return initialState
        default:
            return state
    }
}

export default ukHcpApplyOnlinePopupReducer