import {FranceCaptchaPopupSchema} from "../../schemas/prompts/shared/france-captcha-popup"

const initialState = new FranceCaptchaPopupSchema()

const franceCaptchaPopupReducer = (state = initialState, action) => {
    switch (action.type) {
        case `SHOW_FRANCE_CAPTCHA_POPUP`:
            return action.payload
        case `HIDE_FRANCE_CAPTCHA_POPUP`:
            return initialState
        default:
            return state
    }
}

export default franceCaptchaPopupReducer