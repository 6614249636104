import {AwardsPopupSchema} from "../../../schemas/prompts/awards/ukResidents/awards"

const initialState = new AwardsPopupSchema()

const ukHcpAwardsApplicationPopupReducer = (state = initialState, action) => {
    switch (action.type) {
        case `SHOW_UK_HCP_AWARDS_APPLICATION_POPUP`:
            return action.payload.payload
        case `HIDE_UK_HCP_AWARDS_APPLICATION_POPUP`:
            return initialState
        default:
            return state
    }
}

export default ukHcpAwardsApplicationPopupReducer